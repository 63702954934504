import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFaceSmile,
  faFireBurner,
  faChampagneGlasses,
  faUtensils,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, faViber } from "@fortawesome/free-brands-svg-icons";

library.add(
  faChampagneGlasses,
  faUtensils,
  faFaceSmile,
  faFireBurner,
  faPhoneVolume,
  faWhatsapp,
  faViber
);

export default function Icon({ name, group = "solid" }) {
  return <FontAwesomeIcon icon={`fa-${group} ${name}`} />;
}
