import cn from "classnames/bind";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

const cx = cn.bind(styles);

export default function Button({
  children,
  className,
  color,
  href,
  intLink = false,
  outLink = false,
  type = "button",
  ...rest
}) {
  if (intLink) {
    return (
      <Link
        {...rest}
        to={href}
        className={cx("btn", `btn--${color}`, className)}
      >
        {children}
      </Link>
    );
  }

  if (outLink) {
    return (
      <a
        {...rest}
        href={href}
        className={cx("btn", `btn--${color}`, className)}
      >
        {children}
      </a>
    );
  }

  return (
    <button className={cx("btn", `btn--${color}`, className)} type={type}>
      {children}
    </button>
  );
}
