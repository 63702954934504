import cn from "classnames/bind";
import { Icon } from "components";
import styles from "./index.module.scss";

const cx = cn.bind(styles);

export default function Footer() {
  return (
    <footer className={cx("footer")}>
      <div className={cx("footer__container")}>
        <h6 className={cx("footer__title")}>Ресторан &laquo;Гурман&raquo;</h6>
        <p className={cx("footer__text")}>
          Ресторан Гурман — отличное место для проведения свадебного торжества.
          Элегантный интерьер в светлых тонах отлично гармонирует со свадебным
          оформлением, блюда от шеф-повара точно придутся по вкусу вам и вашим
          гостям, а приветливый персонал создает теплую и уютную атмосферу.
        </p>
        <a
          href="https://yandex.ru/maps/-/CCQ~A6SLhA"
          className={cx("footer__link")}
          target="_blank"
          rel="noreferrer"
        >
          г. Тюмень, ул. Линейная 66
        </a>
        <a href="tel:+79044992299" className={cx("footer__link")}>
          +7 (9044) 99-22-99
        </a>
        <div className={cx("footer-social")}>
          <a
            href="https://wa.me/79044992299"
            className={cx(
              "footer-social__circle",
              "footer-social__circle--whatsapp"
            )}
            target="_blank"
            rel="noreferrer"
          >
            <Icon group="brands" name="fa-whatsapp" />
          </a>
        </div>
      </div>
    </footer>
  );
}
