import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames/bind";
import { MenuLayout } from "layout";
import { Button } from "components";
import MenuCover from "media/images/Menu/menucover.jpg";
import MenuNY from "json/menu-ny.json";
import MenuTakeaway from "json/menu-takeaway.json";
import MenuCelebration from "json/menu-celebration.json";
import MenuRestaraunt from "json/menu-restaraunt.json";
import MenuCaucasian from "json/menu-caucasian.json";
import styles from "./index.module.scss";

const cx = cn.bind(styles);

const meta = {
  title: "Меню ресторана Гурман - ресторан восточной кухни в Тюмени",
  description:
    "Ресторан Гурман — отличное место для проведения свадебного торжества. Элегантный интерьер в светлых тонах отлично гармонирует со свадебным оформлением, блюда от шеф-повара точно придутся по вкусу вам и вашим гостям, а приветливый персонал создает теплую и уютную атмосферу.",
};

export default function Menu() {
  const navigate = useNavigate();
  const { slug } = useParams();

  const [pageTitle, setPageTitle] = useState("");
  const [data, setData] = useState([]);
  useEffect(() => {
    switch (slug) {
      case "new-year":
        setData(MenuNY);
        setPageTitle("Новогоднее меню");
        break;

      case "takeaway":
        setData(MenuTakeaway);
        setPageTitle("Меню на вынос");
        break;

      case "celebration":
        setData(MenuCelebration);
        setPageTitle("Банкетное меню");
        break;

      case "restaraunt":
        setData(MenuRestaraunt);
        setPageTitle("Меню ресторана");
        break;

      case "caucasian":
        setData(MenuCaucasian);
        setPageTitle("Кавказская кухня");
        break;

      default:
        navigate("/", { replace: true });
        break;
    }
  }, [slug]);

  return (
    <MenuLayout meta={meta}>
      <article className={cx("menu__container")}>
        <div
          className={cx("menu__cover")}
          style={{ backgroundImage: `url(${MenuCover})` }}
        />
        <div className={cx("menu-list")}>
          <h1 className={cx("menu__title")}>{pageTitle}</h1>
          <table className={cx("menu-table")}>
            <tbody>
              {data.map(({ title, menu }, i) => (
                <>
                  <tr key={`menu-category-${i}`}>
                    <td
                      key={`menu-title-${i}`}
                      className={cx("menu-table__title")}
                      colSpan={3}
                    >
                      {title}
                    </td>
                  </tr>
                  {menu.map(({ title, description, weight, price }, j) => (
                    <tr key={`menu-item-${j}`}>
                      <td>
                        <span className={cx("menu-table-item__title")}>
                          {title}
                        </span>
                        <br />
                        <span className={cx("menu-table-item__text")}>
                          {description}
                        </span>
                      </td>
                      <td className={cx("menu-table-item__weight")}>
                        {weight}
                      </td>
                      <td className={cx("menu-table-item__price")}>
                        {price > 0 && `${price}₽`}
                      </td>
                    </tr>
                  ))}
                </>
              ))}
            </tbody>
          </table>
          <div className={cx("menu__fab")}>
            <Button
              href="tel:+79044992299"
              className={cx("menu__btn")}
              color="primary"
              outLink
            >
              Сделать заказ
            </Button>
            <Button
              href="/"
              className={cx("menu__btn")}
              color="secondary"
              intLink
            >
              Вернуться на главную
            </Button>
          </div>
          <div className={cx("menu__fab-spacer")}></div>
        </div>
      </article>
    </MenuLayout>
  );
}
