import { Helmet } from "react-helmet";
import cn from "classnames/bind";
import { Footer, Icon } from "components";
import styles from "./index.module.scss";

const cx = cn.bind(styles);

export default function MainLayout({
  meta: { title = "", description = "" },
  children,
}) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <main>{children}</main>

      <a href="tel:+79044992299" className={cx("fab")}>
        <Icon name="fa-phone-volume" />
      </a>
      <Footer />
    </>
  );
}
