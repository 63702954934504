import React, { useState, memo } from "react";
import { Link } from "react-router-dom";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import Media from "react-media";
import cn from "classnames/bind";
import { MainLayout } from "layout";
import { Button, Icon } from "components";
import Snowfall from "react-snowfall";
import MainCarousel1 from "media/images/Main/Carousel/maincarousel-1.jpg";
import MainCarousel2 from "media/images/Main/Carousel/maincarousel-2.jpg";
import MainCarousel3 from "media/images/Main/Carousel/maincarousel-3.jpg";
import MainMenu1 from "media/images/Main/Menu/mainmenu-1.jpg";
import MainMenu2 from "media/images/Main/Menu/mainmenu-2.jpg";
import MainMenu3 from "media/images/Main/Menu/mainmenu-3.jpg";
import MainDelivery from "media/images/Main/Delivery/maindelivery-3.png";
import MainSpecialNewYear from "media/images/Main/Special/mainspecial-new-year.jpg";
import MenuNewYear2025 from "media/images/Main/Special/2025-q.pdf";
import styles from "./index.module.scss";

const cx = cn.bind(styles);

const meta = {
  title: "Гурман - ресторан восточной кухни в Тюмени",
  description:
    "Ресторан Гурман — отличное место для проведения свадебного торжества. Элегантный интерьер в светлых тонах отлично гармонирует со свадебным оформлением, блюда от шеф-повара точно придутся по вкусу вам и вашим гостям, а приветливый персонал создает теплую и уютную атмосферу.",
};

function ResponsiveCarousel() {
  const [slide, setSlide] = useState(0);
  const ref = React.useRef();

  const carouselData = [
    { cover: MainCarousel1 },
    { cover: MainCarousel2 },
    { cover: MainCarousel3 },
  ];

  React.useEffect(() => {
    const intervalID = setTimeout(() => {
      ref.current.goNext();
      setSlide(slide + 1);
    }, 3000);

    return () => clearInterval(intervalID);
  }, [slide]);

  return (
    <div className={cx("carousel__container")}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          return (
            <Media queries={{ small: { maxWidth: 599 } }}>
              {(matches) => (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={CarouselCard}
                  data={carouselData}
                  slideWidth={500}
                  carouselWidth={parentWidth}
                  height={matches.small ? 300 : 800}
                  maxVisibleSlide={matches.small ? 1 : 3}
                  currentVisibleSlide={matches.small ? 1 : 3}
                  disableSwipe={true}
                />
              )}
            </Media>
          );
        }}
      />
    </div>
  );
}

const CarouselCard = memo(
  function (props) {
    const { data, dataIndex } = props;
    const { cover } = data[dataIndex];

    return (
      <div className={cx("carousel__card")}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img className={cx("carousel__img")} draggable={false} src={cover} />
      </div>
    );
  },

  function (prev, next) {
    return prev.dataIndex === next.dataIndex;
  }
);

export default function Main() {
  const renderHeroBlock = () => {
    return (
      <article className={cx("main__block")}>
        <div className={cx("hero")}>
          <div className={cx("hero-body")}>
            <h3 className={cx("main__title")}>Ресторан &laquo;Гурман&raquo;</h3>
            <p className={cx("main__text", "hero-body__text")}>
              Ресторан и банкетный зал с парадной обстановкой и большим меню,
              составленным из блюд восточной и кавказской кухни.
            </p>
            <p className={cx("main__text", "hero-body__text")}>
              Ресторан занимает отдельное двухэтажное здание. Каждый зал
              оформлен в классическом стиле с современным комфортом.
            </p>
            <p className={cx("main__text", "hero-body__text")}>
              Основной зал прекрасно подходит для банкетов и особенных событий,
              в малом приятно собраться компанией до 8 приглашённых.
            </p>
            <div className={cx("hero-disclaimer")}>
              <a href="tel:79044992299" className={cx("hero-disclaimer__link")}>
                +7 (9044) 99-22-99
              </a>
              <a href="tel:73452217585" className={cx("hero-disclaimer__link")}>
                +7 (3452) 21-75-85
              </a>
            </div>
            <Button
              href="#main-menu-block"
              color="primary"
              className={cx("hero-body__btn")}
              outLink
            >
              Посмотреть меню
            </Button>
            <Button
              href="#delivery-block"
              color="primary"
              className={cx("hero-body__btn")}
              outLink
            >
              Самовывоз
            </Button>
            <Button
              href="#yandex-map"
              color="secondary"
              className={cx("hero-body__btn")}
              outLink
            >
              Как добраться?
            </Button>
          </div>
          <div className={cx("hero-cover")}>
            <img
              className={cx("hero-cover__img")}
              src={MainCarousel1}
              alt="Банкетный зал в ресторане Гурман"
            />
          </div>
        </div>
      </article>
    );
  };

  const renderAdvantagesBlock = () => {
    return (
      <article className={cx("main__block")}>
        <div className={cx("advantages")}>
          <div className={cx("advantages-cover")}>
            <ResponsiveCarousel />
          </div>
          <div className={cx("advantages-body")}>
            <h3 className={cx("main__title")}>
              Что предлагает ресторан &laquo;Гурман&raquo;
            </h3>
            <p className={cx("main__text")}>
              Помещение ресторана отличается вместительной площадью, высокими
              потолками и большими панорамными окнами. Лаконичное и безмятежное
              убранство формирует тёплую, гостеприимную обстановку, замечательно
              подходящую для отдыха, неспешного общения и приятного
              времяпровождения в компании близких и друзей.
            </p>
            <div className={cx("advantages-list")}>
              <div className={cx("advantages-list-item")}>
                <div
                  className={cx(
                    "advantages-list-item__circle",
                    "advantages-list-item__circle--green"
                  )}
                >
                  <Icon name="fa-utensils" />
                </div>
                <p className={cx("advantages-list-item__title")}>
                  Отличный сервис
                </p>
                <p className={cx("advantages-list-item__text")}>
                  Большой опыт проведения различных праздничных банкетов и
                  мероприятий
                </p>
              </div>
              <div className={cx("advantages-list-item")}>
                <div
                  className={cx(
                    "advantages-list-item__circle",
                    "advantages-list-item__circle--red"
                  )}
                >
                  <Icon name="fa-champagne-glasses" />
                </div>
                <p className={cx("advantages-list-item__title")}>
                  Банкетный зал
                </p>
                <p className={cx("advantages-list-item__text")}>
                  Проведение банкетов, фуршетов, и корпоративных мероприятий
                </p>
              </div>
              <div className={cx("advantages-list-item")}>
                <div
                  className={cx(
                    "advantages-list-item__circle",
                    "advantages-list-item__circle--orange"
                  )}
                >
                  <Icon name="fa-face-smile" />
                </div>
                <p className={cx("advantages-list-item__title")}>
                  Уютная обстановка
                </p>
                <p className={cx("advantages-list-item__text")}>
                  Уютная обстановка, внимательное обслуживание и вкусные блюда
                </p>
              </div>
              <div className={cx("advantages-list-item")}>
                <div
                  className={cx(
                    "advantages-list-item__circle",
                    "advantages-list-item__circle--blue"
                  )}
                >
                  <Icon name="fa-fire-burner" />
                </div>
                <p className={cx("advantages-list-item__title")}>
                  Мясо на углях
                </p>
                <p className={cx("advantages-list-item__text")}>
                  Большая разновидность ароматных шашлыков приготовленных на
                  углях
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  };

  const renderMenuBlock = () => {
    return (
      <article id="main-menu-block" className={cx("main__block")}>
        <h3 className={cx("main__title")}>
          Меню ресторана &laquo;Гурман&raquo;
        </h3>
        <p className={cx("main__text")}>
          Попадая в стены ресторана Гурман, вновь вспоминаешь эти прекрасные
          традиции и понимаешь, что нет ничего лучше классики! Дизайн интерьера
          выполнен в элегантной манере, но с яркими и современными решениями в
          виде бесподобных кованых диванов.
        </p>
        <div className={cx("menu-list")}>
          <Link
            to="/menu/caucasian"
            className={cx("menu-list-item")}
            style={{ backgroundImage: `url(${MainMenu1})` }}
          >
            <div className={cx("menu-list-item__body")}>
              <p className={cx("menu-list-item__title")}>Блюда на мангале</p>
              <p className={cx("menu-list-item__text")}>
                Мясо, приготовленное на живом огне, удивительно ароматное и
                сочное
              </p>
            </div>
          </Link>
          <Link
            to="/menu/restaraunt"
            className={cx("menu-list-item")}
            style={{ backgroundImage: `url(${MainMenu2})` }}
          >
            <div className={cx("menu-list-item__body")}>
              <p className={cx("menu-list-item__title")}>Меню ресторана</p>
              <p className={cx("menu-list-item__text")}>
                Основные блюда, горячие и легкие закуски, гарниры, десерты и
                напитки
              </p>
            </div>
          </Link>
          <Link
            to="/menu/celebration"
            className={cx("menu-list-item")}
            style={{ backgroundImage: `url(${MainMenu3})` }}
          >
            <div className={cx("menu-list-item__body")}>
              <p className={cx("menu-list-item__title")}>Банкетное меню</p>
              <p className={cx("menu-list-item__text")}>
                Наше банкетное меню идеально подходит для свадьбы, юбилея или
                дня рождения
              </p>
            </div>
          </Link>
        </div>
      </article>
    );
  };

  const renderNewYearBlock = () => {
    return (
      <article
        className={cx(
          "main__block",
          "main__block--fullsize",
          "special-new-year__background"
        )}
        style={{ backgroundImage: `url(${MainSpecialNewYear})`, marginTop: 0 }}
      >
        <div className={cx("main__body special-new-year__container")}>
          <Snowfall />
          <div className={cx("special-new-year__body")}>
            <Snowfall />
            <h3 className={cx("special-new-year__title")}>Дорогие друзья!</h3>
            <p className={cx("special-new-year__text")}>
              Пришло время вновь пригласить вас встретить с нами наступающий{" "}
              {new Date().getFullYear() + 1} год в яркой и незабываемой
              атмосфере.
            </p>
            <p className={cx("special-new-year__text")}>
              Наша праздничная программа включает интерактивные блоки с участием
              приглашённых ведущих — <b>Николая Киселёва</b> и{" "}
              <b>Олега Герасимова</b>.
            </p>
            <p className={cx("special-new-year__text")}>
              Давайте проведём этот Новый год вместе, создавая волшебные
              воспоминания!
            </p>
            <a
              href="tel:73452992288"
              className={cx("hero-disclaimer__link")}
              style={{ margin: "10px 0" }}
            >
              +7 (3452) 99-22-88
            </a>
            <Button
              href={
                `https://docs.google.com/gview?embedded=true&url=` +
                window.location.origin +
                MenuNewYear2025
              }
              target="_blank"
              className={cx("special-new-year__btn")}
              color="primary"
              outLink
            >
              Посмотреть меню
            </Button>
          </div>
        </div>
      </article>
    );
  };

  const renderDeliveryBlock = () => {
    return (
      <article
        id="delivery-block"
        className={cx(
          "main__block",
          "main__block--fullsize",
          "delivery__background"
        )}
        // style={{ backgroundImage: `url(${MainDelivery})` }}
      >
        <span>На вынос дешевле!</span>
        <div className={cx("main__body", "delivery__container")}>
          <div className={cx("delivery__banner", "delivery__banner--left")}>
            <h3
              className={cx(
                "delivery__hero-text",
                "delivery__hero-text--colored"
              )}
            >
              Шашлык!
            </h3>
            <h3 className={cx("delivery__hero-text")}>Шаурма!</h3>
            <p className={cx("delivery__text")}>
              <h3 className={cx("delivery__title")}>
                Закажите <u>самовывоз еды</u> из ресторана
              </h3>
              Наш ресторан предлагает вам самый вкусный шашлык в городе Тюмень.
              <br />
              Ароматный шашлык прямо с мангала. Готовим только из свежего мяса.
              <br />
              <br />
              <span>Без доставки, только самовывоз!</span>
            </p>
            <Button
              href="/menu/takeaway"
              className={cx("delivery__btn")}
              color="primary"
              intLink
            >
              Меню на самовывоз
            </Button>
          </div>
          <div
            className={cx("delivery__banner", "delivery__banner--right")}
          ></div>
        </div>
        <img
          alt="Шаурма на вынос"
          className={cx("delivery__img")}
          draggable={false}
          src={MainDelivery}
        />
      </article>
    );
  };

  const renderMapBlock = () => {
    return (
      <article
        id="yandex-map"
        className={cx(
          "main__block",
          "main__block--fullsize",
          "ymaps__container"
        )}
      >
        <div className={cx("ymaps")}>
          <YMaps>
            <Map
              width="100%"
              height="500px"
              defaultState={{ center: [57.094466, 65.59615], zoom: 18 }}
            >
              <Placemark geometry={[57.094466, 65.59615]} />
            </Map>
          </YMaps>
        </div>
      </article>
    );
  };

  return (
    <MainLayout meta={meta}>
      <>
        {renderNewYearBlock()}
        {renderHeroBlock()}
        {renderAdvantagesBlock()}
        {renderDeliveryBlock()}
        {renderMenuBlock()}
        {renderMapBlock()}
      </>
    </MainLayout>
  );
}
