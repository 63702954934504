import { Helmet } from "react-helmet";
// import cn from "classnames/bind";
// import styles from "./index.module.scss";

// const cx = cn.bind(styles);

const defaultMeta = {
  title: "",
  description: "",
};

export default function MenuLayout({
  meta: { title = defaultMeta.title, description = defaultMeta.description },
  children,
}) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>

      <main>{children}</main>
    </>
  );
}
